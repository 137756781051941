.title {
  font-family: var(--ff-bold) !important;
  font-size: var(--fs-30) !important;
  color: var(--clr-black);
}

.weekday {
  font-size: var(--fs-16) !important;
  font-family: var(--ff-bold) !important;
  color: var(--clr-black) !important;
  /* margin-block-end: 5px; */
}

.checkbox {
  width: unset !important;
  margin-inline-end: 1rem;
}

.inputContainer {
  pointer-events: none !important;
}