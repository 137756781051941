.logo {
  max-width: 220px;
  max-height: 25px;
}
.copyRight,
.privacy {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-12) !important;
  color: var(--clr-darkGrey);
}

.title {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-grey);
}
