html, body{
  overflow-x: hidden;
}

.title {
    font-family: var(--ff-bold) !important;
    font-size: var(--fs-28) !important;
    color: var(--clr-black);
  }

  .subTitle {
    font-family: var(--ff-bold) !important;
    font-size: var(--fs-22) !important;
    color: var(--clr-black);
  }
  
  .subTitleSmall {
    font-family: var(--ff-bold) !important;
    font-size: var(--fs-18) !important;
    color: var(--clr-black);
  }

  .label {
    font-size: var(--fs-16);
    font-family: var(--ff-bold);
    color: var(--clr-black);
    margin-block-end: 5px;
  }
  .star {
    color: var(--clr-red);
  }
  
  .weekday {
    font-size: var(--fs-16) !important;
    font-family: var(--ff-bold) !important;
    color: var(--clr-black) !important;
    /* margin-block-end: 5px; */
  }
  
  .checkbox {
    width: unset !important;
    margin-inline-end: 1rem;
  }
  
  .inputContainer {
    pointer-events: none !important;
  }
