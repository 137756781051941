.container{
border: 1px solid rgb(224, 224, 224);
margin: 5px 0px 0px 0px;
font-size: 14px;
font-weight: 600;

}

hr{
  opacity: 10%;
  width: 25%;
}

.antPickerDisabled {
  background-color: #F5F5F5 !important;
}