.carousel :global .carousel-control-prev,
.carousel :global .carousel-control-next {
  display: none;
}

.carousel :global .carousel-indicators {
  /* right: unset;
  top: unset;
  left: unset;
  bottom: unset;
  margin: 0px;
  inset-block-start: 65% !important;
  inset-inline-start: 5% !important; */
  display: none;
}

/* .carousel :global .carousel-indicators button {
  border: 1px solid var(--clr-dearBlue);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: transparent;
} */

/* .carousel :global .carousel-indicators button.active {
  background-color: var(--clr-dearBlue);
} */

.sliderImg {
  max-height: 550px;
  object-fit: cover;
}

.textContainer {
  position: absolute;
  inset-block-start: 10%;
  inset-inline-start: 5%;
  width: 50%;
}

.header {
  font-family: var(--ff-light) !important;
  font-size: var(--fs-64) !important;
  color: var(--clr-black);
}

.body {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-20) !important;
  color: var(--clr-black);
  font-weight: 600;
}

.linkButton {
text-decoration: none;
}

@media (max-width: 768px) {
  .header {
    font-size: var(--fs-22) !important;
  }
  .body{
    font-size: var(--fs-14) !important;
  }
  .textContainer{
    width: 90%;
  }
}
