/* components.module.css */
.containerLanding {
    max-width: 1280px;
    margin: auto;
    padding: 0px 10px;
  }
  
  @media (max-width: 768px) {
    .containerLanding {
        padding: 0px 16px;
    }
  }