.searchInput {
  margin: 0px !important;
  padding-block: 0px !important;
  border-radius: unset !important;
  background-color: white;
}

.filterMenu {
  width: 100% !important;
}

.filterMenu div {
  border-radius: 0 !important;
}

.permissionModal :global .ant-modal-content {
  min-height: 270px !important;
  display: flex;
}
.permissionModal :global .ant-modal-body {
  width: 100% !important;
}
