.coloredBlock {
  width: 25px;
  height: 25px;
  background-color: #c4c4c4;
}

.header {
  font-family: var(--ff-bold) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-black);
}
.body {
  font-family: var(--ff-Regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-black);
}
