.headerTitle{
  font-size: 6.4rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.headerSubTitle{
  font-size: 2.4rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.bookDemoBtn{
  margin-top: 20px;
  font-size: 1.3rem;
  font-weight: 700;
  border: 3px solid #0095DA;
  text-decoration: none;
  color: white;
  transition: all 1s ease-in-out;
  background-color: transparent;

}

.bookDemoBtn:hover{
  background-color: #0095DA;
  color: white;
  transition: all 1s ease-in-out;
}

.sectionBorder{
  border: 2px solid black;
  border-radius:  2rem;
}

.letterSpacing{
  letter-spacing: 1px;
}

.learnMoreBtn{
  margin-top: 20px;
  font-size: 1.3rem;
  font-weight: 700;
  text-decoration: none;
  color: white;
  background-color: black;
}

.learnMoreBtn:hover{
  color: white;
}

.testimonial {
  background-color: #0095DA;
}

.footerTitle{
  font-size: 3.2rem;
  font-weight:400;
}

.footerSubTitle{
  font-size: 1.5rem;
  font-weight: 200;
}

.responsiveHero{
  max-width: 100%;
  width: 700px;
  min-width: 400px; 
}

.responsiveFooterImage{
  max-width: 100%;
  width: 500px;
  min-width: 300px; 
}

@media (max-width: 768px) {

  .headerTitle{
    font-size: 4rem;
  }
  
  .headerSubTitle{
    font-size: 1.4rem;
    text-align: center;
   
  }
  
  .bookDemoBtn{
    font-size: 1rem;
  }
  
  .learnMoreBtn{
    transition: all 1s ease-in-out;
    font-size: 1rem;
  }

  .footerTitle{
    font-size: 2rem;
    font-weight:400;
    text-align: center;
  }
  
  .footerSubTitle{
    font-size: 1rem;
    font-weight: 200;
    text-align: center;
  }
}

.header {
  font-family: var(--ff-light) !important;
  font-size: var(--fs-40) !important;
  color: var(--clr-darkGrey);
}

.subHeader {
  font-family: var(--ff-regular) !important;
  font-weight: 400;
  font-size: var(--fs-18) !important;
  color: var(--clr-darkGrey);
}

.ownDataBox {
  box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
    0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 12px ;
}
