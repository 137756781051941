.footerContainer {
  background: #1e1b28;
}

.footerHeader {
  font-size: var(--fs-16) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-white);
  font-weight: 500;
}

.footerText {
  font-size: var(--fs-14) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-white);
  font-weight: 400;
}

.footerLink,
.copyRight {
  font-size: var(--fs-16) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-white);
  font-weight: 400;
  cursor: pointer;
}
