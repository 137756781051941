.addDiv {
  font-size: var(--fs-bold) !important;
  font-family: var(--ff-18) !important;
  color: var(--clr-current);
  margin-inline-start: 5px !important;
  margin-inline-end: 10px !important;
  cursor: pointer;
}

.trackingMat {
  font-size: var(--fs-12) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-midGrey);
}

.search {
  margin-bottom: 0px !important;
}

.smallButton {
  width: 75%;
  /* margin-top: 3px; */
}

.smallButton1 {
  width: 90%;
}

.filterSectionText {
  font-family: var(--ff-medium);
  font-size: var(--fs-14);
  color: var(--clr-grey);
}

@media (max-width: 700px) {
  .collapse {
    font-size: 11px !important;
    overflow-y: unset !important;
    overflow-x: auto;
  }

  .trackingMat {
    font-size: 11px !important;
  }
}

.pendingApprovalItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-block: 2vw 1vw; */
  margin-bottom: 10px;
  border: 1px solid rgb(224, 224, 224);
  background-color: rgb(251, 251, 251);
  border-radius: 5px;
  padding: 1vw;
  flex-wrap: wrap;
}

button.antBtn {
  display: inline-flex;
  align-items: center;
}

Button {
  display: inline-flex;
  align-items: center;
}

Button.antBtn:disabled,
Button[disabled] {
  display: inline-flex;
  align-items: top;
  
}

.pendingApprovalItemChekedApproved {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid rgb(186, 223, 181);
  background-color: rgb(251, 251, 251);
  border-radius: 5px;
  padding: 1vw;
  flex-wrap: wrap;
}

.pendingApprovalItemChekedRejected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid rgb(232, 190, 190);
  background-color: rgb(251, 251, 251);
  border-radius: 5px;
  padding: 1vw;
  flex-wrap: wrap;
}

.pendingApprovalBoxTxt {
  display: flex;
  flex-direction: column;
  /* gap: 1vw; */
  width: 100%;
}
.timestampSpan {
  opacity: 0.5;
  margin-top: 2px;
}

.btnsCon {
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 2px;
  margin-top: 5px;
  /* margin-left: -5px; */
}

.pendingApprovalInput {
  width: 100%;
  margin-top: 10px;
}

.pendingNotes {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}

.acceptAllBtn {
  gap: 10px;
  display: flex;
  justify-content: end;
}

.pendingApprovalModal {
  width: 50% !important;
}

.ItemsPendingCon {
  display: flex;
  gap: 3px;
  /* border: 1px solid red; */
  /* justify-content: space-between; */
}

.notification {
  display: flex;
  gap: 3px;
  border: 1px solid red;
}

.notificationDot {
  content: "";
  width: 7px;
  height: 7px;
}

.itemsPendingApprovalhistory {
  height: 40vh;
  overflow-y: scroll;
  width: 100%;
}

/* .itemsPendingApproval {
  height: 50vh;
  overflow-y: scroll;
  margin-top: 1vw;
  width: 100%;
} */

.pendinghistory {
  margin-bottom: 10px;
  border: 1px solid rgb(224, 224, 224);
  background-color: rgb(251, 251, 251);
  border-radius: 5px;
  padding: 1vw;
}

.dateAndNotes {
  display: flex;
  justify-content: space-between;
}

.timestamp {
  margin-top: 2px;
  opacity: 0.5;
}
