.membersList{
    max-height: 20vh;
    overflow-y: scroll;
}
.memberItem{
    padding: 8px 10px;
}
.memberItem:hover,
.clearBtn:hover,
.chosenFilteredMember{
    background-color: beige;
}
.clearBtn{
    padding: 8px 6px;
}