.header {
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-28) !important;
}

.btnText {
  color: var(--clr-white);
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-18) !important;
}

.linkStyle {
  color: black;
  text-decoration: none;
}
.linkStyle:hover {
  color: black;
  text-decoration: none;
}

.linkStyleNoPermission {
  color: black;
  text-decoration: none;
  cursor: default;
}
.linkStyleNoPermission:hover {
  color: black;
  text-decoration: none;
  cursor: default;
}
