.RadioText {
  font-size: var(--fs-14) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-black);
}

.header {
  font-size: var(--fs-23) !important;
  font-family: var(--ff-medium) !important;
  color: var(--clr-black);
}
