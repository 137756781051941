.iconsContainer{
  border: 1px solid #D9D9D9;
  padding: 6px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date{
  font-size: var(--fs-16) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-midGrey);
}
.filtersApplied{
  background-color: rgb(229, 235, 235);
}