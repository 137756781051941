.container {
  background-color: var(--clr-white);
  border: 1px solid var(--clr-aluminium);
  border-radius: 5px;
  /* width: 20px; */
  width: 100px;

  transition: 0.5s width ease-in-out;
  padding: 5px;
  z-index: 0;
}

.containerDashboard {
  background-color: var(--clr-white);
  border: 1px solid var(--clr-aluminium);
  border-radius: 5px;
  width: 100px;
  transition: 0.5s width ease-in-out;
  padding: 5px;
  z-index: 0;
}

/* .container:hover {
  width: 100px;
  z-index: 1;
} */
.containerDashboard:hover {
  width: 100px;
  z-index: 1;
}

.noGrow {
  width: fit-content !important;
  padding-inline: 10px !important;
  justify-content: center;
  column-gap: 5px;
}

.noGrow:hover {
  width: fit-content !important;
}

.icon {
  width: 10px;
  height: 10px;
}
.draft,
.pending,
.overdue,
.completed,
.activated {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-12) !important;
}

.draft {
  color: var(--clr-midGrey) !important;
}
.pending {
  color: var(--clr-orange) !important;
}
.overdue {
  color: var(--clr-red) !important;
}
.completed,
.activated {
  color: var(--clr-current) !important;
}

@media (max-width: 720px) {
  .containerDashboard {
    width: 20px;
  }
  .containerDashboard:hover {
    width: 100px;
  }
}
