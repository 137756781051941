.label {
  font-size: var(--fs-16);
  font-family: var(--ff-bold);
  color: var(--clr-black);
  margin-block-end: 5px;
}
.star {
  color: var(--clr-red);
}

.input:focus-visible {
  border-color: black;
  outline: none;
}

.input::-ms-expand {
  display: none;
}
.input {
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("assets/images/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position-y: 50%;
  background-color: #fcfcfc;
  border: 1px solid #c8c8c8;
  padding: 10px;
  cursor: pointer;
  background-position-x: 95% !important;
  border-radius: 10px;
  color: var(--clr-black);
  font-family: var(--ff-regular);
  font-size: var(--fs-16);
  padding-block: 5px;
  padding-inline: 15px;
  width: 100%;
}

.input:disabled {
  background: #d8d8d8;
  border: 1px solid black;
  cursor: not-allowed;
}

.error_msg {
  display: block;
  margin-top: 4px;
  color: #e93c3c;
  line-height: 22px;
  text-align: start;
}
