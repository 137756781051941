.background {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 300px;
}

.pageTitle {
  font-family: var(--ff-light) !important;
  font-size: var(--fs-60) !important;
  color: var(--clr-white);
  position: absolute;
  inset-block-end: 10%;
  inset-inline-start: 10%;
}
