.filterItemsList{
    max-height: 25vh;
    overflow-y: scroll;
}
.filterItem{
    padding: 8px 10px;
}
.filterItem:hover,
.chosenFilteredItem{
    background-color: beige;
}