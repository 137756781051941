.header {
  font-family: var(--ff-medium) !important;
    font-size: var(--fs-28) !important;
}

.btnText {
  color: var(--clr-white);
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-18) !important;
}

:global(.ant-tabs-nav-list) {
  display: flex;
  align-items: center;
  justify-content: left;
}
:global(.ant-tabs-tab) {
  width: 100%;
  justify-content: center;
}

:global( .ant-tabs-nav) {
  height: 45px;
}
