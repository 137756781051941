.page {
  /* max-height: 100vh; */
  /* max-height: 100svh; */
  /* overflow: hidden; */
}

.logo {
  max-width: 220px;
  max-height: 25px;
}

.login {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-grey);
}

.welcome {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-28) !important;
  color: var(--clr-black);
}

.thirdPartyLoignIcon {
  max-width: 100px;
}

.forgetPass,
.link {
  font-size: var(--fs-16) !important;
  font-family: var(--ff-regular) !important;
}

.link {
  color: #467dd8;
}

.remember {
  color: var(--clr-grey);
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-14) !important;
}

.copyRight,
.privacy {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-12) !important;
  color: var(--clr-darkGrey);
}
.errorText {
  text-align: center;
  font-size: 14px !important;
  color: red;
  padding-block: 5px;
}
@media (max-width: 768px) {
  .page {
    overflow: unset;
  }
}
