.header {
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-28) !important;
}

.btnText {
  color: var(--clr-white);
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-18) !important;
}
