.label {
  font-size: var(--fs-16) !important;
  font-family: var(--ff-bold) !important;
  color: var(--clr-black);
  margin-block-end: 5px !important;
}
.input {
  display: none;
}
.btn {
  font-size: var(--fs-16);
  font-family: var(--ff-regular);
  color: #969696;
  background: #fcfcfc;
  border: 1px solid #c8c8c8;
  border-radius: 12px;
  padding-block: 10px;
}

.btn:hover {
  background: #fcfcfc;
  border: 1px solid #c8c8c8;
}
