/* sf pro display  */
@font-face {
  font-family: "roboto-bold";
  src: url("./Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-medium";
  src: url("./Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-regular";
  src: url("./Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-italic";
  src: url("./Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-light";
  src: url("./Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-thin";
  src: url("./Roboto-Thin.ttf") format("truetype");
}
