.header {
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-28) !important;
}
.btnText {
  color: var(--clr-white);
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-18) !important;
}

.tableHeader {
  color: var(--clr-dearBlue);
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-28) !important;
  
}

.tableContainer :global .MuiTableCell-head {
  color: var(--clr-midGrey);
  background-color: rgb(247, 247, 247);
  font-family: var(--ff-medium);
}

.tbody :is(.tableRow) div {
  padding-block: 5px;
}
.tbody :is(.tableRow) + tr td {
  padding-top: 60px;
}

.tableRow {
  position: relative;
  margin-top: 500px;
}
.companyName{
  display: flex;
  flex-direction: column;
}
.label{
  font-size: var(--fs-16);
  font-family: var(--ff-bold);
  color: var(--clr-black);
  /* margin-block-end: 5px; */
}

.star {
  color: var(--clr-red);
}

.divisionCompany {
  background-color: var(--clr-lightGrey);
  color: var(--clr-black);
  font-size: var(--fs-14);
  font-family: var(--ff-medium);
  padding-inline: 5px;
  width: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.modalHeader {
  color: var(--clr-midNight);
  font-size: var(--fs-23) !important;
  font-family: var(--ff-bold) !important;
}

.modalSubHeader {
  color: var(--clr-midGrey);
  font-size: var(--fs-20) !important;
  font-family: var(--ff-regular) !important;
}

.modalContainer {
  width: 70vw;
  background-color: #f4f5f7;
  border-radius: 10px;
}

.margins {
  margin-inline: 10%;
}

.modalContainer :global .ant-tabs-nav::before {
  border-bottom: none;
}

.modalContainer :global .ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #707b8f !important;
  font-size: var(--fs-20) !important;
  font-family: var(--ff-medium) !important;
}
.modalContainer :global .ant-tabs-tab-btn {
  color: var(--clr-black) !important;
  font-family: var(--ff-regular) !important;
}

.modalContainer :global .ant-tabs-tab-active:hover {
  color: #707b8f !important;
}

.modalContainer :global .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #707b8f !important;
}

.modalContainer :global .ant-tabs-nav-operations {
  display: none !important;
}

.danger {
  background-color: var(--clr-red);
}

button.antBtn {
  display: inline-flex;
  align-items: center;
}