.container{
    background-color: var(--clr-dearBlue);
    width: 100%;
    /* margin-top: 15px; */
}

.header{
    font-size: var(--fs-40) !important;
    font-family: var(--ff-light) !important;
    color: var(--clr-white);
}

.subHeader{
    font-size: var(--fs-regular) !important;
    font-family: var(--ff-18) !important;
    color: var(--clr-white);
}

.btn{
    background-color: var(--clr-white) !important;
    color: var(--clr-dearBlue) !important;
}