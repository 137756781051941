.logo {
  max-width: 220px;
  max-height: 25px;
}

.title {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-grey);
}

.welcome {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-28) !important;
  color: var(--clr-black);
}

.copyRight,
.privacy {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-12) !important;
  color: var(--clr-darkGrey);
}

.backgroundContainer{
  /* background-image: url('assets/images/login-hero.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 100vh; 
}