.trackingMat {
  font-size: var(--fs-12) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-midGrey);
}

/* sub division item style */
.divisionHeader {
  background-color: var(--clr-lightGrey);
  border-radius: 6px;
  height: 100%;
  width: 100%;
  padding: 5px 5px;
  display: flex;
  align-items: center;
}

.blueBorder {
  border-inline-start: 8px solid var(--clr-current);
}

.orangeBorder {
  border-inline-start: 8px solid #FF5201;
}

.redBorder {
  border-inline-start: 8px solid #D32F2F;
}

.subDivisonName {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
}

.arrow {
  transition: transform 1s ease-in-out;
}

.activeArrow {
  color: var(--clr-current);
  transform: rotate(90deg);
}

.nonActive {
  transform: rotate(0deg);
}

.numberOfItems {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
}

.colored {
  color: var(--clr-current);
}

.sheduleSelect {
  width: 130px;
  border: 1px solid #c8c8c8;
  padding: 4px;
  border-radius: 6px;
}

.childRow {
  transition: max-height 0.3s ease;
}

.hiddenChild {
  display: none !important;
}

/* end of sub division item  */

/* start of table  */

.tableHead {
  border-style: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.tableHead th {
  height: 30px;
  width: 300px !important;
  padding: 5px;
}

.tableHeadText {
  font-family: var(--ff-bold) !important;
  font-size: var(--fs-14) !important;
  color: var(--clr-black);
  line-height: 20px;
}

.projectDD:where(.css-dev-only-do-not-override-1me4733).ant-picker .ant-picker-input>input[disabled] {
  color: #009900;
}

.tableHeadBg {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-lightGrey);
  border-radius: 6px;
  padding: 5px 10px;
  height: 100% !important;
  width: 130px;
  
}
.tableHeadBgCheckBox {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--clr-lightGrey);
  border-radius: 6px;
  height: 100% !important;
  width: 130px;
}

.normalCells {
  width: 130px !important;
}

.smallCells {
  width: 40px !important;
}

.ff {
  background-color: transparent;
}

.lateColored {
  /* border: 1px solid red !important; */
  background-color: #fff5f5;
  border: 1px solid #ff0000 !important;
  border-radius: 7px !important;
}

.pendingColored {
  background-color: #fcf7f7;
  border: 1px solid #EC9700 !important;
  border-radius: 7px !important;
}

.green {
  background-color: rgb(234, 255, 236);
  border: 1px solid #009900;
  border-radius: 7px;
}



.Tabledata {
  /* background: #f5faff; */
  border-radius: 6px;
  display: flex;
  width: fit-content;
  padding: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #c8c8c8;
}

.Tabledata:hover {
  cursor: no-drop;
}

.tableText {
  font-size: var(--fs-12) !important;
  font-family: var(--ff-bold) !important;
  text-align: center;
  max-width: 110px;
}

.tableRow td {
  height: 50px !important;
  padding: 5px;
}

.tableContainer {
  max-height: 500px;
  overflow-y: unset !important;
  overflow-x: auto;
  /* max-height: 1000px; */
  transition: max-height 0.5s ease-in-out, opacity 0.25s ease-in-out;
}

.notAllowed {
  cursor: no-drop;
}

.notAllowed input,
.notAllowed select {
  /* cursor: not-allowed; */
  pointer-events: none;
  color: grey;
}

/* .notAllowed .exception input {
  background-color: #ff0000 !important;
} */

.exception {
  pointer-events: auto !important;
  cursor: text !important;
  color: black !important;
}

.exception input {
  pointer-events: auto !important;
  cursor: text !important;
  color: black !important;
}

.customDisabled {
  pointer-events: none !important;
  cursor: no-drop !important;
  color: grey !important;
}

.collapsedTable {
  opacity: 0;
  max-height: 0px;
}

.deleteButton {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledDeleteButton {
  transition: all 1s ease-in-out;
  color: gray;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}

.disabledDeleteButton tr td :last-child {
  background-color: #ff0000 !important;
  z-index: 5;
}

.projectDelDate {
  border-radius: 6px;
  display: flex;
  width: fit-content;
  /* padding: 10px; */
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #c8c8c8;
  margin-top: 5px;
}

.deletedRow,
.deletedRow div {
  transition: all 1s ease-in-out;
  background-color: grey;
  opacity: 0.6;
  pointer-events: none;
}

.actions,
.actions div,
.actions svg {
  pointer-events: all;
  transition: all 1s ease-in-out;
  background-color: white;
}

.disabledRow input,
.disabledRow select {
  pointer-events: none !important;
  color: grey;
  cursor: not-allowed !important;
}