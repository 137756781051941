.tableHead {
  font-family: var(--ff-bold) !important;
  font-size: var(--fs-16) !important;
  color: var(--clr-midGrey);
  line-height: 20px;
}

.tableHead {
  border-style: hidden;
}
.tableHead tr th {
  padding: 15px 5px;
}

.projectRow td {
  padding: 15px 5px;
}

.rowText {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-16) !important;
  color: var(--clr-black);
  line-height: 20px;
  text-decoration: none;
  color: black;
}

.rowText:hover {
  color: var(--clr-black);
  text-decoration: none;
  color: black;
}

.col {
  width: 10%;
}
.bigCells {
  width: 25%;
}

.progressBar {
  width: 70%;
}

.link{
  text-decoration: none;
}

.link:hover{
  text-decoration: none;
  color: black;
}

@media (max-width: 1200px) {
  .tableContainer {
    overflow-y: unset !important;
    overflow-x: auto;
  }

  .mediumCells {
    width: 300px !important;
  }
}

@media (max-width: 1120px) {
  .tableContainer {
    overflow-y: unset !important;
    overflow-x: auto;
  }
  .rowText,
  .tableHead {
    font-size: 11px !important;
  }

  .col {
    width: auto;
  }
  .bigCells {
    width: 300px !important;
  }
}
