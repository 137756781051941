.ProcurementManagerSection,
.responsibleContractorSection {
    /* background-color: #EAECF0; */
    border-top: 1px solid var(--clr-lowBlue);
}

.ProcurementManager,
.responsibleContractor {
    /* border-bottom: 1px solid var(--clr-lowBlue); */
    padding: 10px 0px;
    color: #44536D;
    font-size: var(--fs-18);
    font-weight: var(--fs-54);
}

.searchInput {
    margin: 10px;
    max-width: 50%;
    max-height: 52%;
    padding: 0;
}

.deleteButton {
    opacity: .5;
    cursor: no-drop;
}

.name {
    color: var(--clr-black);
    font-size: var(--fs-18);
    font-weight: var(--fs-54);
}


.activityTracking {
    background-color: var(--clr-lightBLUE);
    padding: 10px;

}

.activityTrackingHeader {

    font-size: var(--fs-20);
    font-weight: 400;
    color: var(--clr-dearBlue);
}

.activityTrackingText {
    font-size: 14px;
    font-weight: 700;
    color: var(--clr-dearBlue);
}

.activityTrackingInfo {
    font-size: 12px;
    font-weight: 400;

}

.actionBtn {
    text-decoration: underline;
    margin-right: 5px;
    cursor: pointer;
}

.ScrollDiv {
    max-height: 48vh;
    overflow-y: scroll;
    /* margin-bottom: 12rem; */
}