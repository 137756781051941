.header {
  font-size: var(--fs-28) !important;
  font-family: var(--ff-bold) !important;
  color: var(--clr-white);
}

.body {
  font-size: var(--fs-18) !important;
  font-family: var(--ff-bold) !important;
  color: var(--clr-white);
}

.carousel :global .carousel-control-prev,
.carousel :global .carousel-control-next {
  display: none;
  height: 100%;
  width: 100%;
}
.sliderImg{
  object-fit: cover;
  width: 100%;
  height: 100vh;
}