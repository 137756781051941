/* TABLE */
.tableContainer{
  margin-top: 10px;
}

.tableContainer thead{
  border-bottom: 2px solid var(--clr-lowBlue);
}

.tableContainer .tableFilterSection{
  list-style: none;
  display: flex;
  gap: 2rem;
  padding-inline-start: 8px;
  padding-block-start: 4px;
  margin-bottom: 0.8rem;
}
.tableContainer .tableFilterSection li{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.tableContainer th,
.tableContainer td{
  border: unset;
  padding: unset;
  padding-inline-start: 8px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableContainer th{
  font-family: var(--ff-medium);
  font-size: var(--fs-18);
}

.firstCell{
  position: relative;
  padding-inline-start: 15px !important;
}
.firstCell::before{
  content: 'o';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.personCell{
  background-color: var(--clr-dearBlue);
  width: 50px;
  height: 50px;
  padding-inline-start: unset !important;
  margin-block: 8px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-inline-start: 8px;
}

.filterContainer{
  /* background-color: #4ab7e866; */
    padding: 5px 15px;
    border-radius: 5px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterContainer span{
  color: var(--clr-dearBlue)
}