.title {
    font-family: var(--ff-bold) !important;
    font-size: var(--fs-30) !important;
    color: var(--clr-black);
  }

  .mainCard{
    /* background: linear-gradient(90deg, #d3edff, #d3edff); */
    
    border: 1px solid #E9E9E9;
    border-radius: 1.7rem;
    width: 250px; 
    transition: all 0.5s ease-in-out; 
  }

  

  .panelStyling{
    /* background-color: rgb(241, 241, 241); */
    background-color: #F8F8F8;
    
    transition: all 0.3s ease-in-out; 
  }
  
  .panelStyling:hover{
    background-color: #F8F8F8;
  }

  .detailsCard{
    width: 180px;
    border-radius: 1.7rem;
    color: black;
    background-color: white;
    border: 1px solid #E9E9E9;
  }

  .detailsCard .cardTitle{
    font-size: 0.9rem;
  }