.title {
  font-family: var(--ff-bold) !important;
  font-size: var(--fs-28) !important;
  color: var(--clr-dearBlue);
}
.body {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-black);
}
.img {
  border-radius: 12px;
}

.header {
  font-family: var(--ff-light) !important;
  font-size: var(--fs-40) !important;
  color: var(--clr-black);
}

.subHeader {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-black);
}
