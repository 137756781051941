.sidemenu {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  background-color: #F4F5F7;
  min-width: 300px;
  /* max-width: 450px; */
  height: 100vh;
  /* overflow-y: auto; */
  z-index: 10;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}

.rightStyle {
  inset-inline-end: 0;
  inset-inline-start: unset;
  max-width: 50%;
  
}

.layer {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 500vh;
  z-index: 5;
  background-color: #00000050;
  backdrop-filter: blur(1px);
}

.titleSection {
  padding-inline: 10px;
  padding-bottom: 2px;
  gap: 20px;
  border-bottom: 2px solid var(--clr-lowBlue);
}

.title {
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-23) !important;
  color: #44536d;
}
.subTitle{
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-12) !important;
  color: #999999;

}
.close {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidemenu {
    width: 75%;
  }
}
