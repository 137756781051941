.label {
  font-size: var(--fs-16);
  font-family: var(--ff-bold);
  color: var(--clr-black);
  margin-block-end: 5px;
}
.star {
  color: var(--clr-red);
}

.ant-picker {
  border: none !important;
}

.dateContainer img {
  width: 20px;
  margin-inline-start: 2px;
  margin-right: -8px;
}

.dateContainer {
  display: flex;
  border: 1px solid #c8c8c8;
  border-radius: 7px;
  padding: -5px;
  padding: 9px 0px;
  background-color: transparent;
}
.container {
  background: #fcfcfc;
  /* border: 1px solid #c8c8c8;
  border-radius: 12px; */
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  padding-block: 5px;
  padding-inline: 10px;
  /* /////////// */
  display: flex;
  min-height: 33px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input {
  background: transparent;
  border: none;
  color: var(--clr-black);
  font-size: var(--fs-16);
  font-family: var(--ff-regular);
  width: 100%;
  height: 100%;
}

.input:focus-visible {
  outline: none;
}

.textarea {
  resize: none;
}

.errorContainer {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: absolute;
  inset-block-end: -38px;
  padding: 10px;
  z-index: 5;
}

.error_msg {
  color: #e93c3c;
  line-height: 25px;
  font-size: var(--fs-16) !important;
  font-family: var(--ff-regular) !important;
  font-weight: 400;
  margin-inline-start: 8px !important;
}

.errorIcon {
  width: 20px;
  height: 20px;
}
