.header {
  font-family: var(--ff-bold) !important;
  font-size: var(--fs-32) !important;
  /* color: var(--clr-dearBlue); */
  color: black;
}


@media (min-width: 980px) {
  
  .contactSection{
    background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.5)), 
    url('assets/images/contact-us-heroo.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
  }
}




.subHeader {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-black);
}

.requestText {
  font-weight: 400;
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-14) !important;
  color: var(--clr-black);
}

.textarea {
  height: 220px;
}

.container{
  min-height: 96vh;
}

.contactus,
.mail {
  font-weight: 400;
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-14) !important;
  color: var(--clr-black);
}

.mail{
  color: var(--clr-red);
}

.buttonStyling{
  padding: 15px !important;
}

.responsiveHero{
  max-width: 100%;
  width: 900px;
  min-width: 400px; 
}


@media (max-width: 468px) {
  .contactSection{
    width: 100%;
    } 

  .container{
      min-height: 50vh;
    }

}