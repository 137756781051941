.button {
  border: none;
  border-radius: 12px;
  background-color: var(--clr-dearBlue);
  font-size: var(--fs-16);
  font-family: var(--ff-bold);
  color: var(--clr-white);
  width: 100%;
  text-align: center;
}

.button:focus {
  box-shadow: none;
}

.button:hover {
  opacity: 0.9;
}

.button:disabled {
  background-color: var(--clr-platinum) !important;
  color: var(--clr-white) !important;
  cursor: no-drop;
}
