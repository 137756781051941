@import "bootstrap/dist/css/bootstrap.min.css";
@import "./assets/fonts/fonts.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--clr-lightGrey);
}

.pointer {
  cursor: pointer;
}

.ant-picker-disabled {
  background: none !important;
  /* color: #000000 !important; */
}

.checkbox {
  width: fit-content !important;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.nav-rest {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

:root {
  /* colors */
  --clr-black: #000000;
  --clr-midNight: #222222;
  --clr-lightGrey: #f4f4f4;
  --clr-grey: #4a4d4e;
  --clr-midGrey: #999999;
  --clr-darkGrey: #333333;
  --clr-platinum: #afafaf;
  --clr-silver: #cccccc;
  --clr-aluminium: #ebebeb;
  --clr-anthracite: #666666;
  --clr-red: #e60000;
  --clr-maroon: #990000;
  --clr-violet: #9c2aa0;
  --clr-aubergine: #5e2750;
  --clr-green: #009900;
  --clr-springGreen: #a8b400;
  --clr-orange: #eb9700;
  --clr-aqua: #00b0ca;
  --clr-turquoise: #007c92;
  --clr-blue: #0560fd;
  --clr-dearBlue: #004c92;
  --clr-white: #ffffff;
  --clr-current: #0095d9;
  --clr-lightBLUE: #eaecf0;
  --clr-lowBlue: #d0ddf3;

  --ff-bold: "roboto-bold";
  --ff-medium: "roboto-medium";
  --ff-regular: "roboto-regular";
  --ff-italic: "roboto-italic";
  --ff-light: "roboto-light";
  --ff-thin: "roboto-thin";

  /* font sizes */
  --fs-12: clamp(0.75rem, 0.625vw, 14px);
  --fs-14: clamp(0.8rem, 0.729vw, 16px);
  --fs-15: clamp(0.8rem, 0.781vw, 16px);
  --fs-16: clamp(14px, 0.833vw, 18px);
  --fs-17: clamp(15px, 0.885vw, 20px);
  --fs-18: clamp(16px, 0.938vw, 20px);
  --fs-20: clamp(18px, 1.042vw, 30px);
  --fs-21: clamp(18px, 1.094vw, 30px);
  --fs-22: clamp(18px, 1.146vw, 34px);
  --fs-23: clamp(18px, 1.198vw, 36px);
  --fs-26: clamp(19px, 1.354vw, 37px);
  --fs-28: clamp(20px, 1.458vw, 38px);
  --fs-30: clamp(25px, 1.563vw, 40px);
  --fs-32: clamp(28px, 1.667vw, 45px);
  --fs-37: clamp(28px, 1.927vw, 45px);
  --fs-40: clamp(30px, 2.083vw, 50px);
  --fs-48: clamp(34px, 2.5vw, 54px);
  --fs-52: clamp(40px, 2.708vw, 60px);
  --fs-54: clamp(42px, 2.813vw, 62px);
  --fs-60: clamp(50px, 3.125vw, 65px);
  --fs-64: clamp(50px, 3.333vw, 70px);
  --fs-70: clamp(60px, 3.646vw, 75px);
  --fs-80: clamp(70px, 4.167vw, 85px);
}

.ant-picker-input {
  min-width: 105% !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-progress-text {
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-12) !important;
  color: var(--clr-platinum) !important;
  line-height: 20px;
}

@media (max-width: 768px) {
  .ant-progress-text {
    font-size: 11px;
  }
}

.main-nav-title {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-26) !important;
  color: var(--clr-black);
}

.main-nav-desc {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: var(--clr-midGrey);
}

.no-padding-input {
  margin-bottom: 0px !important;
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

.ant-collapse {
  overflow: hidden;
}

.btn-danger,
.delete-btn {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

#rc-tabs-1-panel-1,
#rc-tabs-1-panel-2,
#rc-tabs-1-panel-3 {
  white-space: pre-wrap;
  padding: 20px;
}

.ant-tabs-tab {
  padding: 10px !important;
  margin: 10px !important;
}

.ant-table-wrapper
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon:nth-child(:not(1)) {
  opacity: 0 !important;
}

tr[data-row-key="1"] .ant-table-row-expand-icon-cell {
  /* background-color: red !important; */
  opacity: 0;
  visibility: hidden;
  background-color: transparent !important;
}

tr[data-row-key="3"] .ant-table-row-expand-icon-cell {
  /* background-color: red !important; */
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  background-color: transparent;
  position: relative;
  z-index: -9;
}
tr[data-row-key="4"] .ant-table-row-expand-icon-cell {
  /* background-color: red !important; */
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  background-color: transparent;
  position: relative;
  z-index: -9;
}

.allowCon {
  display: flex;
  gap: 10px;
}

.ant-table-wrapper .ant-table-row-expand-icon-collapsed::after {
  content: unset !important;
}

.ant-table-wrapper .ant-table-row-expand-icon-collapsed::before {
  content: unset !important;
}

.ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  content: url("../public/images.png");
  transform: rotate(90deg);
  border: none !important;
}

button[aria-expanded="true"] {
  transform: rotate(180deg) !important;
}
