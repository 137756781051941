.navbar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.backgroundColor {
background-color: black;
}

.simpleShadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-logo {
  max-width: 150px;
  max-height: 20px;
  /* padding: 10px; */
}

.navLink {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-20) !important;
  color: var(--clr-darkGrey);
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  padding: 10px !important;
  text-decoration: none;
}

.navLinkLanding {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-20) !important;
  color: var(--clr-white);
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  padding: 10px !important;
  text-decoration: none;
}

.navLink:hover{
  color: black;
}

.navLinkLanding:hover{
  color: white;
}

.activeLink {
  background-color: #F4F4F4;
  color: #0196db;
  border-radius: 10px;
}
.activeLink:hover {
  color: #0196db;
}

.activeLinkLanding {
  background-color: var(--clr-darkGrey);
  color: white;
  border-radius: 10px;
}
.activeLinkLanding:hover {
  color: white;
}

.navbarToggleCustom {
  color: white;
  filter: invert(1);
 }

.actionsContainer {
  width: max-content;
}

.btn {
  width: unset !important;
  /* flex: 1; */
  padding-inline: 50px;
}

.link {
  font-family: var(--ff-regular) !important;
  font-size: var(--fs-18) !important;
  color: #0196db;
  /* text-decoration: underline; */
  cursor: pointer;
  /* flex: 1; */
}

.letterContainer {
  background-color: var(--clr-dearBlue);
  border-radius: 50% ;
  padding: 7px;
  cursor: pointer;
}

.firstLetters {
  font-family: var(--ff-medium) !important;
  font-size: var(--fs-16) !important;
  color: var(--clr-white);
  user-select: none;
}
