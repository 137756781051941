.header{
    font-size: var(--fs-16) !important;
    font-family: var(--ff-medium) !important;
    color: var(--clr-black);  
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 25px;
}

.user {
  font-size: var(--fs-14) !important;
  font-family: var(--ff-regular) !important;
  color: var(--clr-white);
  border-radius: 50%;
  padding: 5px;
  /* background-color: var(--clr-dearBlue); */
}

.negativeMargin {
  margin-inline-start: -10px;
}
